import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./App.css";
import logo from "./assets/images/logo.png";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import ResidentialScreen from "./screens/ResidentialScreen";
import CommercialScreen from "./screens/CommercialScreen";
import InfrastructuralScreen from "./screens/InfrastructuralScreen";
import InfrastructuralPMScreen from "./screens/InfrastructuralPMScreen";
import InteriorScreen from "./screens/InteriorScreen";
import ContactScreen from "./screens/ContactScreen";
import RealEstateScreen from "./screens/RealEstateScreen";
import RoadConstructionScreen from "./screens/RoadConstructionScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faHome,
  faBuilding,
  faAddressCard,
  faAddressBook,
  faToolbox,
  faPalette,
  faRoad
} from "@fortawesome/free-solid-svg-icons";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  const toggleMenu = () => {
    document.querySelector(".sidebar").classList.toggle("open");
  };

  const [openMenu, setOpenMenu] = useState(false);
  // const [openPmMenu, setOpenPmMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  const dropdown = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const handleClick = e => {
    console.log("event", e.target);
    if (openMenu) {
      if (
        !dropdown?.current?.contains(e.target) &&
        !buttonRef?.current?.contains(e.target)
      ) {
        setOpenMenu(false);
      }
    }
  };

  const menu = () => {
    setOpenMenu(!openMenu);
  };

  const sideMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <header className="header">
          <div className="brand">
            <Link to="/">
              <img src={logo} alt="" className="brand-logo" />
            </Link>
          </div>

          {width > 1000 ? (
            <div className="header-links">
              <Link to="/" className="dropdown-link">
                Home
              </Link>
              <Link to="/about-us" className="dropdown-link">
                About Us
              </Link>
              {/*<div className="dropdown">
                <button
                  className="dropdown-button"
                  onClick={menu}
                  ref={buttonRef}
                >
                  Projects
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="dropdown-icon"
                  />
                </button>
                {openMenu && (
                  <div
                    ref={dropdown}
                    className={
                      openMenu ? "dropdown-contentAnimate" : "dropdown-content"
                    }
                  >
                    <Link
                      to="/residential"
                      className="content-link"
                      onClick={menu}
                    >
                      Residential Projects
                    </Link>
                    <Link
                      to="/commercial"
                      className="content-link"
                      onClick={menu}
                    >
                      Commercial Projects
                    </Link>
                    <Link
                      to="/infrastructural"
                      className="content-link"
                      onClick={menu}
                    >
                      Infrastructural Projects
                    </Link>
                    <Link
                      to="/interior"
                      className="content-link"
                      onClick={menu}
                    >
                      Interior Design
                    </Link>
                    <Link
                      to="/road-construction"
                      className="content-link"
                      onClick={menu}
                    >
                      Road Construction
                    </Link>
                  </div>
                )}
              </div>*/}
              <ul className="dropdown">
                <li className="dropdown-button" id="pm">
                  <span id="pmSpan">
                    Projects
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="dropdown-icon"
                    />
                  </span>
                  <ul className="dropdown-contentAnimatePm">
                    <li>
                      <Link
                        to="/residential"
                        className="content-link"
                        onClick={menu}
                      >
                        Residential Projects
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/commercial"
                        className="content-link"
                        onClick={menu}
                      >
                        Commercial Projects
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/infrastructural"
                        className="content-link"
                        onClick={menu}
                      >
                        Infrastructural Projects
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/interior"
                        className="content-link"
                        onClick={menu}
                      >
                        Interior Design
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/road-construction"
                        className="content-link"
                        onClick={menu}
                      >
                        Road Construction
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <Link to="/real-estate" className="dropdown-link">
                Real Estate Development
              </Link>

              <ul className="dropdown">
                <li className="dropdown-button" id="pm">
                  <span id="pmSpan">
                    Project Management
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="dropdown-icon"
                    />
                  </span>
                  <ul className="dropdown-contentAnimatePm">
                    <li>
                      <Link to="/infrastructural-pm" className="content-link">
                        Infrastructural Projects
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <Link to="/contact" className="dropdown-link">
                Contacts
              </Link>
            </div>
          ) : (
            <button
              id="head-btn"
              className="header-button"
              onClick={toggleMenu}
            >
              &#9776;
            </button>
          )}
        </header>

        <aside className="sidebar">
          <ul className="slide-menu">
            <li>
              <FontAwesomeIcon icon={faHome} className="sidebar-icon" />
              <Link onClick={toggleMenu} to="/">
                Home
              </Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faAddressCard} className="sidebar-icon" />
              <Link to="/about-us" onClick={toggleMenu}>
                About Us
              </Link>
            </li>

            <li>
              <FontAwesomeIcon icon={faToolbox} className="sidebar-icon" />
              <Link to="" onClick={sideMenu}>
                Projects
              </Link>
            </li>

            {showMenu && (
              <ul
                className={
                  showMenu ? "slide-innerMenuAnimate" : "slide-innerMenu"
                }
              >
                <li className="slide-innerList">
                  <FontAwesomeIcon icon={faHome} className="sidebar-icon" />
                  <Link to="/residential" onClick={toggleMenu}>
                    Residential
                  </Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
                  <Link to="/commercial" onClick={toggleMenu}>
                    Commercial
                  </Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
                  <Link to="/infrastructural" onClick={toggleMenu}>
                    Infrastructural
                  </Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPalette} className="sidebar-icon" />
                  <Link to="/interior" onClick={toggleMenu}>
                    Interior Design
                  </Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faRoad} className="sidebar-icon" />
                  <Link to="/road-construction" onClick={toggleMenu}>
                    Road Construction
                  </Link>
                </li>
              </ul>
            )}

            <li>
              <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
              <Link to="/real-estate" onClick={toggleMenu}>
                Real Estate
              </Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
              <Link to="/infrastructural-pm" onClick={toggleMenu}>
                Project Management
              </Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faAddressBook} className="sidebar-icon" />
              <Link to="/contact" onClick={toggleMenu}>
                Contact
              </Link>
            </li>
          </ul>
        </aside>

        <main className="main-div">
          <Route path="/" component={HomeScreen} exact={true} />
          <Route path="/about-us" component={AboutScreen} />
          <Route path="/residential" component={ResidentialScreen} />
          <Route path="/commercial" component={CommercialScreen} />
          <Route path="/infrastructural" component={InfrastructuralScreen} />
          <Route
            path="/infrastructural-pm"
            component={InfrastructuralPMScreen}
          />
          <Route path="/interior" component={InteriorScreen} />
          <Route path="/real-estate" component={RealEstateScreen} />
          <Route path="/contact" component={ContactScreen} />
          <Route path="/road-construction" component={RoadConstructionScreen} />
        </main>
      </div>
    </Router>
  );
}

export default App;
