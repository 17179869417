import React from "react";
import "./RoadConstructionScreen.css";
import Footer from "../components/Footer";

function RoadConstructionSCreen() {
  return (
    <div className="container">
      <div className="road-construction-hero">
        <h1>Road Construction</h1>

        <p>Coming soon!</p>
      </div>
      <Footer />
    </div>
  );
}

export default RoadConstructionSCreen;
