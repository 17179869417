import React, { useState } from "react";
import "./ContactScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelopeOpen,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import MyMap from "../components/MyMap";

function ContactScreen() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const position = [6.577182592682142, 3.367458483668249];

  const handleSubmit = e => {
    e.preventDefault();
    // alert("submit form");

    // let templateParams = {
    //   from_name: email,
    //   name: name,
    //   phone: phone,
    //   company: company,
    //   to_name: "<YOUR_EMAIL_ID>",
    //   message_html: message
    // };
  };
  return (
    <div className="container">
      <div className="contact-info">
        <div className="map-container">
          <MyMap
            center={position}
            position={position}
            text={"Sirius Construction Company"}
            body={"3 Maryland Cresent, Lagos, Nigeria"}
          />
        </div>
        <div className="contact-container">
          <div className="contact-group">
            <FontAwesomeIcon icon={faPhone} className="contact-icon" />
            <div className="contact-contact">
              <h2> Telephone Number</h2>
              <p>+234 700 555 8888 </p>
              <p>+234 1 229 5555 </p>
            </div>
          </div>

          <div className="contact-group">
            <FontAwesomeIcon icon={faEnvelopeOpen} className="contact-icon" />
            <div className="contact-contact">
              <h2> Email</h2>
              <p>contact@siriusconstruction.com</p>
            </div>
          </div>

          <div className="contact-group">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <div className="contact-contact">
              <h2> Address</h2>
              <p>3 Maryland Crescent, Lagos, Nigeria </p>
            </div>
          </div>
        </div>
      </div>

      <h1 className="contact-get">GET IN TOUCH</h1>

      <div className="contact-form">
        <form onsubmit={handleSubmit} className="contact-input-container">
          <div className="contact-input-group">
            <input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              className="form-input"
              placeholder="name"
            />

            <input
              type="tel"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              className="form-input"
              placeholder="phone"
            />
          </div>

          <div className="contact-input-group">
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="form-input"
              placeholder="email"
            />

            <input
              type="text"
              value={company}
              onChange={e => setCompany(e.target.value)}
              className="form-input"
              placeholder="company"
            />
          </div>

          <textarea
            type="text"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="message"
            className="contact-textarea"
          />
        </form>
        <button className="contact-button" onClick={handleSubmit}>
          Contact Us
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default ContactScreen;
