import React, { useState, useRef } from "react";
import Footer from "../components/Footer";
import "./CommercialScreen.css";
// import data from "./projects";
import ImageViewer from "../components/ImageViewer";
import projectData from "../helpers/data";

function CommercialScreen() {
  let commercials = projectData.find(x => x.name === "Commercial");
  console.log({ commercials });

  const [openModal, setOpenModal] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  const ref = useRef();

  const modal = data => {
    setOpenModal(!openModal);
    setCurrentProject(data);
  };

  const check = e => {
    console.log("event>>", e.target);
    console.log("ref>>>", ref.current);
    if (ref.current.contains(e.target)) {
      return;
    } else {
      modal();
    }
  };
  return (
    <div className={!openModal ? "container " : "stop_scroll container "}>
      <div className="commercial-hero">
        <h1>Commercial Projects</h1>
        <h2>
          We closely work with businesses across board to create flexible,
          future proof workspaces that will increase efficiency of business
          operations.
        </h2>
      </div>

      <div className="project__body">
        {openModal && (
          <div className="modal_container">
            <ImageViewer
              close={modal}
              project={currentProject}
              ref={ref}
              check={check}
            />
          </div>
        )}

        {commercials &&
          commercials.projects.map((el, idx) => {
            return (
              <div
                key={idx}
                className="project__body__card"
                onClick={() => modal(el)}
              >
                <div className="project__body__img">
                  <img src={el.images[0]} alt="project_img" />
                </div>
                <div className="project__body__text">
                  <h2>{el.projectName}</h2>
                </div>
              </div>
            );
          })}
      </div>
      <Footer />
    </div>
  );
}
export default CommercialScreen;
