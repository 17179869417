import React from "react";
import "./RealEstateScreen.css";
import Footer from "../components/Footer";

function RealEstateScreen() {
  return (
    <div className="container">
      <div className="real-estate-hero">
        <h1>Real Estate Development</h1>

        <p>Coming soon!</p>
      </div>
      <Footer />
    </div>
  );
}

export default RealEstateScreen;
