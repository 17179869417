import React, { useState, useRef } from "react";
import Footer from "../components/Footer";
import "./InfrastructuralScreen.css";

import ImageViewer from "../components/ImageViewer";
import projectData from "../helpers/data";
function InfrastructureScreen() {
  let infrastructures = projectData.find(x => x.name === "Infrastructural");

  const [openModal, setOpenModal] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  const ref = useRef();

  const modal = data => {
    setOpenModal(!openModal);
    setCurrentProject(data);
  };

  const check = e => {
    console.log("event>>", e.target);
    console.log("ref>>>", ref.current);
    if (ref.current.contains(e.target)) {
      return;
    } else {
      modal();
    }
  };
  return (
    <div className={!openModal ? "container " : "stop_scroll container "}>
      <div className="infastructural-hero">
        <h1>Infrastructural Projects</h1>
        <h2>
          Our infrastructural projects are deployed alongside global best
          companies to meet our clients objectives and goals.
        </h2>
      </div>
      <div className="project__body">
        {openModal && (
          <div className="modal_container">
            <ImageViewer
              close={modal}
              project={currentProject}
              ref={ref}
              check={check}
            />
          </div>
        )}

        {infrastructures &&
          infrastructures.projects.map((el, idx) => {
            return (
              <div
                key={idx}
                className="project__body__card"
                onClick={() => modal(el)}
              >
                <div className="project__body__img">
                  <img src={el.images[0]} alt="project_img" />
                </div>
                <div className="project__body__text">
                  <h2>{el.projectName}</h2>
                </div>
              </div>
            );
          })}
      </div>
      <Footer />
    </div>
  );
}
export default InfrastructureScreen;
