import React, { useState, useRef } from "react";
import Footer from "../components/Footer";
import "./InteriorScreen.css";
import ImageViewer from "../components/ImageViewer";
import projectData from "../helpers/data";
function InteriorScreen() {
  let interiors = projectData.find(x => x.name === "Interior Design");

  const [openModal, setOpenModal] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  const ref = useRef();

  const modal = data => {
    setOpenModal(!openModal);
    setCurrentProject(data);
  };

  const check = e => {
    console.log("event>>", e.target);
    console.log("ref>>>", ref.current);
    if (ref.current.contains(e.target)) {
      return;
    } else {
      modal();
    }
  };
  return (
    <div className={!openModal ? "container " : "stop_scroll container "}>
      <div className="interior-hero">
        <h1>Interior Design</h1>
        <h2>
          Our interior designs are tailored to create suitable interior spaces
          for client’s luxury and comfort. We work with global best companies to
          meet budgets and luxury requirements.
        </h2>
      </div>
      <div className="project__body">
        {openModal && (
          <div className="modal_container">
            <ImageViewer
              close={modal}
              project={currentProject}
              ref={ref}
              check={check}
            />
          </div>
        )}

        {interiors &&
          interiors.projects.map((el, idx) => {
            return (
              <div
                key={idx}
                className="project__body__card"
                onClick={() => modal(el)}
              >
                <div className="project__body__img">
                  <img src={el.images[0]} alt="project_img" />
                </div>
                <div className="project__body__text">
                  <h2>{el.projectName}</h2>
                </div>
              </div>
            );
          })}
      </div>
      <Footer />
    </div>
  );
}
export default InteriorScreen;
