import React, { useState, useRef } from "react";
import Footer from "../components/Footer";
import "./ResidentialScreen.css";
import ImageViewer from "../components/ImageViewer";
import projectData from "../helpers/data";

function ResidentialScreen() {
  // modal open ? document.body.style.overflow to hidden, and set it to scroll when closed
  let residentials = projectData.find(x => x.name === "Residential Projects");

  const [openModal, setOpenModal] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  const ref = useRef();

  const modal = data => {
    setOpenModal(!openModal);
    setCurrentProject(data);
  };

  const check = e => {
    console.log("event>>", e.target);
    console.log("ref>>>", ref.current);
    if (ref.current.contains(e.target)) {
      return;
    } else {
      modal();
    }
  };

  // console.log({ currentProject });
  return (
    <div className={!openModal ? "container " : "stop_scroll container "}>
      <div className="residential-hero">
        <h1>Residential Projects</h1>
        <h2>
          Sirius construction has been building, remodelling and improving homes
          with reliable and professional services for over 16 years. We combine
          high quality materials and workmanship with contemporary designs and
          fittings to create your desirable homes.
        </h2>
      </div>

      <div className="project__body">
        {openModal && (
          <div className="modal_container">
            <ImageViewer
              close={modal}
              project={currentProject}
              ref={ref}
              check={check}
            />
          </div>
        )}

        {residentials &&
          residentials.projects.map((el, idx) => {
            return (
              <div
                key={idx}
                className="project__body__card"
                onClick={() => modal(el)}
              >
                <div className="project__body__img">
                  <img src={el.images[0]} alt="project_img" />
                </div>
                <div className="project__body__text">
                  <h2>{el.projectName}</h2>
                </div>
              </div>
            );
          })}
      </div>

      <Footer />
    </div>
  );
}
export default ResidentialScreen;
